import { Helmet, HelmetData } from "react-helmet-async";
import Questions from "./questions";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const helmetData = new HelmetData({});

export default function AllFAQs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] FAQs</title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/faqs"
        />
        <meta name="description" content="" />
        <meta
          name="keywords"
          content="future, AI, powered by AI, blog, cutting-edge research, innovative startups, game-changing applications, artificial intelligence, industries"
        />
      </Helmet>
      <main className="container flex flex-col md:gap-[100px] gap-[50px] pt-[200px] pb-[150px] md:px-[50px]  text-white">
        <div className="flex flex-col gap-[20px] items-center justify-center">
          <span className="px-[24px] py-[10px] rounded-[100px] text-white border-blue border">
            FAQs
          </span>
          <h1 className="md:text-[38px] text-[24px] font-[800] text-center">
            Frequently Asked Questions
          </h1>
          <p className="text-center text-textColor font-[400] leading-[35px]">
            We know you’ve got some questions
          </p>
        </div>
        <div className="max-w-[1000px] mx-auto">
          <Questions />
        </div>
      </main>
    </>
  );
}
