import React from "react";

export const LikeIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3332 6.66669C15.3332 6.31307 15.1927 5.97393 14.9426 5.72388C14.6926 5.47383 14.3535 5.33335 13.9998 5.33335H9.7865L10.4265 2.28669C10.4398 2.22002 10.4465 2.14669 10.4465 2.07335C10.4465 1.80002 10.3332 1.54669 10.1532 1.36669L9.4465 0.666687L5.05984 5.05335C4.81317 5.30002 4.6665 5.63335 4.6665 6.00002V12.6667C4.6665 13.0203 4.80698 13.3594 5.05703 13.6095C5.30708 13.8595 5.64622 14 5.99984 14H11.9998C12.5532 14 13.0265 13.6667 13.2265 13.1867L15.2398 8.48669C15.2998 8.33335 15.3332 8.17335 15.3332 8.00002V6.66669ZM0.666504 14H3.33317V6.00002H0.666504V14Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33317 6.00002V14H0.666504V6.00002H3.33317ZM5.99984 14C5.64622 14 5.30708 13.8595 5.05703 13.6095C4.80698 13.3594 4.6665 13.0203 4.6665 12.6667V6.00002C4.6665 5.63335 4.81317 5.30002 5.05984 5.06002L9.4465 0.666687L10.1532 1.37335C10.3332 1.55335 10.4465 1.80002 10.4465 2.07335L10.4265 2.28669L9.79317 5.33335H13.9998C14.3535 5.33335 14.6926 5.47383 14.9426 5.72388C15.1927 5.97393 15.3332 6.31307 15.3332 6.66669V8.00002C15.3332 8.17335 15.2998 8.33335 15.2398 8.48669L13.2265 13.1867C13.0265 13.6667 12.5532 14 11.9998 14H5.99984ZM5.99984 12.6667H12.0198L13.9998 8.00002V6.66669H8.13984L8.89317 3.12002L5.99984 6.02002V12.6667Z"
        fill="white"
      />
    </svg>
  );
};
