import React from "react";

export const GlobeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_707_261)">
        <path
          d="M1.3335 7.99998C1.3335 11.682 4.31816 14.6666 8.00016 14.6666C11.6822 14.6666 14.6668 11.682 14.6668 7.99998C14.6668 4.31798 11.6822 1.33331 8.00016 1.33331C4.31816 1.33331 1.3335 4.31798 1.3335 7.99998Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.66675 1.36664C8.66675 1.36664 10.6668 3.99997 10.6668 7.99997C10.6668 12 8.66675 14.6333 8.66675 14.6333M7.33342 14.6333C7.33342 14.6333 5.33342 12 5.33342 7.99997C5.33342 3.99997 7.33342 1.36664 7.33342 1.36664M1.75342 10.3333H14.2468M1.75342 5.66664H14.2468"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_707_261">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
