import { Helmet, HelmetData } from "react-helmet-async";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { client } from "../../../client";

const helmetData = new HelmetData({});

export default function SingleBlog() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [allPosts, setAllPosts] = useState({});
  const [relatedPosts, setRelatedPosts] = useState(null);

  // useEffect(()=>{
  //   fetch(`https://cms.analyticsintelligence.ai/api/mainsite-blogs/${id}`)
  //   .then(res => res.json())
  //   .then(post => {
  //     setPost(post.data)
  //   })

  //   fetch(`https://cms.analyticsintelligence.ai/api/mainsite-blogs`)
  //   .then(res => res.json())
  //   .then(post => {
  //     setAllPosts(post.data)
  //   })
  // },[id])
  useEffect(() => {
    client
      .fetch(
        `*[_type == "Title"]{
      title,
      author,
      body,
      author_avatar,
      cover_photo,
      tags,
      published_date,
      featured_post,
      id
    }`
      )
      .then((data) => {
        setPost(data.filter((d) => d.id == id)[0]);
        setAllPosts(data);
        console.log(data.filter((d) => d.id == id)[0]);
      })
      .catch(console.error);
  }, [id]);
  const match = (array1, arraysObj) => {
    const arrays = Object.values(arraysObj);
    return arrays.filter((arr) =>
      arr.tags.some((element) => array1.includes(element))
    );
  };
  useEffect(() => {
    if (allPosts && post) {
      const related = match(post.tags, allPosts);
      setRelatedPosts(related);
    }
  }, [allPosts, post]);

  console.log(relatedPosts);
  return (
    <>
      {post && (
        <Helmet helmetData={helmetData}>
          <title>{post.title}</title>
          <meta
            name="description"
            content={
              post?.body?.split(" ").slice(0, 30).join(" ") +
              (post?.body?.split(" ")?.length > 10 ? "..." : "")
            }
          />
          <link rel="canonical" href={`/blog/${id}/`} />
          <meta property="og:title" content={post?.title} />
          <meta
            property="og:description"
            content={
              post?.body.split(" ").slice(0, 30).join(" ") +
              (post?.body.split(" ")?.length > 10 ? "..." : "")
            }
          />
          <meta property="og:image" content={post?.cover_photo} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`/blog/${id}`} />
        </Helmet>
      )}

      {post && (
        <div className="flex flex-col text-white pb-[50px]">
          <div className="">
            <div className="container pt-[200px] pb-[100px]">
              <div className="flex flex-col gap-[30px]">
                <div className="flex flex-row flex-wrap gap-[20px] items-start">
                  {post?.tags &&
                    post?.tags.map((tag) => (
                      <span
                        className="rounded-[100px] px-[12px]  py-[12px] border"
                        key={tag}
                      >
                        {tag}
                      </span>
                    ))}
                </div>
                <h1 className="text-[1.5rem] sm:text-[2rem]">{post?.title}</h1>
                <div className="flex items-center gap-[10px]">
                  <div className="flex items-center gap-[5px]">
                    <img
                      className="object-cover rounded-[50%] h-[44px] w-[44px] border border-[3px] border-[#F3F3F3]"
                      src={post?.author_avatar}
                      alt="avatar"
                    />
                    <span>{post?.author}</span>
                  </div>
                  <p>
                    {new Date(post?.published_date).toLocaleDateString(
                      "en-US",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container overflow-hidden mx-auto">
            <img
              src={post?.cover_photo}
              alt="event"
              className="h-[80vh] mx-auto"
            />
          </div>
          <div className="container flex flex-col mt-[50px] pb-[150px] gap-[20px] text-white">
            <div className=" body flex flex-col max-w-[1060px] justify-center mx-auto sm:text-[18px] leading-8">
              <ReactMarkdown children={post?.body} className="markdown" />
            </div>
            <div className="flex flex-col gap-[15px] md:w-[30%]">
              <h1 className="text-[18px] font-bold">Share</h1>
              <div className="flex gap-[10px]">
                <FacebookShareButton
                  url={window.location.href}
                  quote={post?.title}
                >
                  <FacebookIcon size={50} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={post?.title}
                >
                  <TwitterIcon size={50} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={window.location.href}
                  title={post?.title}
                >
                  <WhatsappIcon size={50} />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={window.location.href}
                  title={post?.title}
                >
                  <TelegramIcon size={50} />
                </TelegramShareButton>
              </div>
            </div>
          </div>
          {relatedPosts && (
            <span className="container font-[400] text-[32px] mb-[30px]">
              Related post
            </span>
          )}
          <div className="container flex flex-wrap gap-[30px]">
            {relatedPosts &&
              relatedPosts.map((p) =>
                p.id !== post.id ? (
                  <Link
                    reloadDocument
                    to={`/blog/${p?.id}`}
                    className="lg:w-[31.5%] md:w-[48%] w-full bg-[#242424] rounded-[10px] p-[13px]"
                    key={p?.id}
                  >
                    <div className="max-h-[200px] overflow-hidden">
                      <img src={p?.cover_photo} alt="event" className="brand" />
                    </div>
                    <div className="flex flex-col gap-[12px] justify-between min-h-[200px] mt-[24px]">
                      <h3 className="font-[400] md:text-[22px] text-[20px]">
                        {p?.title}
                      </h3>
                      <div className="flex flex-col gap-[20px]">
                        <div className="flex">
                          <ReactMarkdown
                            className="markdown"
                            children={
                              p.body.split(" ").slice(0, 10).join(" ") +
                              (p.body.split(" ").length > 10 ? "..." : "")
                            }
                          />
                        </div>
                        <div className="flex justify-between">
                          <div className="flex items-center gap-[10px]">
                            <div className="flex items-center gap-[5px]">
                              <img
                                className="object-cover rounded-[50%] h-[44px] w-[44px] border border-[3px] border-[#F3F3F3]"
                                src={post?.author_avatar}
                                alt="avatar"
                              />
                              <span>{post?.author}</span>
                            </div>
                            <p>
                              {new Date(
                                post?.published_date
                              ).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  ""
                )
              )}
          </div>

          <style jsx="true">{`
            .swiper-button-next,
            .swiper-button-prev {
              color: #000;
            }
            .swiper-pagination-bullet-active {
              background-color: #000;
            }
            .body p {
              margin-bottom: 20px;
            }
            .body h2 {
              font-size: 1.3rem;
            }
            .body img {
              max-width: 800px;
              max-height: 800px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-item: center;
            }
            .body a:hover {
              color: lightblue;
            }
            .body a {
              text-decoration: underline;
            }
            .brand {
              height: 200px;
              width: 100%;
              object-fit: cover;
            }
            .speakers-header {
              font-weight: 500;
              text-transform: uppercase;
              margin-top: 1rem;
              color: #555;
              margin-bottom: 1rem;
            }
            .profile {
              display: flex;
              align-items: center;
              margin-bottom: 1.5rem;
            }
            .profile img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 1rem;
            }
            .profile h5 {
              font-size: 0.9rem;
            }
            .profile h6 {
              font-size: 0.8rem;
              font-weight: normal;
            }

            .other {
              text-align: center;
            }
            .banner .title {
              margin-bottom: 2rem;
              color: rgba(31, 53, 105, 1);
            }

            .card-base .content {
              padding: 0px;
              margin-top: 2rem;
              width: 100%;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              background-color: #fff;
              overflow: hidden;
            }

            .text-body {
              color: #000;
            }
            .text-body .btn-base {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
            }

            .card-base {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .card-base h4 {
              font-size: 1.2rem;
              font-weight: 600;

              margin-bottom: 1rem;
            }
            .card-base a {
              color: inherit;
              text-decoration: none;
            }
            .card-base p {
              margin-top: 1rem;
              margin-bottom: 1rem;
            }

            .products {
              background-color: #fff;
              padding-top: 5rem;
              padding-bottom: 5rem;
              color: #1f3569;
            }
            .deliver {
              padding-top: 3rem;
              padding-bottom: 3rem;
            }
            .both {
              display: grid;
              grid-template-columns: 3fr 2fr;
              align-items: center;
              justify-content: center;
            }
            .both-media {
              padding: 2rem;
            }

            @media only screen and (max-width: 800px) {
              .both {
                grid-template-columns: 1fr;
              }
            }
            .both-text p {
              font-size: 1.3rem;
            }
            .deliver h2 {
              margin-bottom: 1rem;
              font-size: 1.9rem;
            }
            .banner {
              margin-top: 85px;
              background: linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0.8),
                  rgba(0, 0, 0, 0.8)
                ),
                url(${post.cover_photo});
              padding-top: min(8rem, 10vw);
              padding-bottom: min(10rem, 15vw);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 25%;
              color: #fff;
            }
            .house {
              max-width: 700px;
            }
            .house p {
              font-size: 1rem;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
            .btn-group button {
              padding: 1rem 4rem;
              margin-bottom: 1rem;
              font-size: 1.2rem;
            }
            .btn-group .btn-white {
              margin-right: 1rem;
            }
            .btn-base {
              padding: 1rem 3rem;
              margin-bottom: 1rem;
              font-size: 1.1rem;
              margin-top: 1rem;
            }
            // .bg-header{
            //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
            //   background-repeat: no-repeat;
            //   background-size: 100%;
            //   // background-position: center center;
            // }
          `}</style>
        </div>
      )}
    </>
  );
}
