import React from "react";
import { hydrate } from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { createRoot } from "react-dom/client";
import "./index.css";
import ScrollToTop from "./components/main/scrollToTop";

const tagManagerArgs = {
  gtmId: "GTM-K8ZW6GN",
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <ScrollToTop />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>,
    rootElement
  );
} else {
  const root = createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  );
}
