// import Sliders from "./sliders";
import { Helmet, HelmetData } from "react-helmet-async";
import MainBtn from "../button/MainBtn";
import { Link } from "react-router-dom";
import Questions from "../faq/questions";
import Partners from "./partners";

const helmetData = new HelmetData({});

export default function Main() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence Africa | Empower your Operations with AI
          and Data
        </title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/"
        />
        <meta
          name="description"
          content="The growth of data in today's digital world is fuelling the growth of analytics across the enterprise and the development of artificial intelligence in various sectors. Our goal is to make analytics and artificial intelligence accessible to anyone, across Africa."
        />
        <meta
          name="keywords"
          content="data growth, digital world, analytics, enterprise, artificial intelligence, development, sectors, accessibility, Africa"
        />
      </Helmet>
      <main className="flex  flex-col lg:pt-[200px] pt-[150px] text-white">
        <div className="">
          <div className="container w-full flex flex-col lg:flex-row justify-between items-center lg:gap-[135px] gap-[44px] lg:pb-[50px] pb-[44px]">
            <div className="lg:w-[50%] flex flex-col gap-[20px] items-start">
              <h1 className="lg:text-[38px] text-[24px] font-[800]">
                Empower your <span className="text-blue">Operations</span> with
                AI and Data
              </h1>
              <p className="text-[16px] leading-8 text-textColor">
                Do you know what your Data is telling you? Make the most of your
                data with our advanced AI solutions. Seamlessly integrate AI
                into your operations and let your data lead the way to smarter
                decisions and greater success
              </p>
              <div className="flex items-center gap-[32px]">
                <MainBtn>
                  <Link to="/demo">Book a demo</Link>
                </MainBtn>
              </div>
            </div>
            <div className="lg:w-[50%]">
              <img
                className=""
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656707/Group_14106_alveot.png"
                alt="illustration"
              />
            </div>
          </div>
        </div>
        <div>
          <p className="sm:text-[16px] leading-8 text-textColor text-center">
            [AI] partners with top AI companies, African governments, and
            innovative enterprises
          </p>
          <Partners />
        </div>
        <div
          className="flex bg flex-col sm:gap-[150px] gap-[100px] lg:pt-[50px] pb-[44px]"
          id="product"
        >
          <div className="container flex flex-col gap-[50px] items-center">
            <div className="flex flex-col items-center gap-[20px] text-center lg:px-[100px] max-w-[1200px] mx-auto">
              <h1 className="lg:text-[28px] text-[20px]">
                Intelligence at your Fingertips
              </h1>
              <p className="lg:text-[16px] text-[14px] leading-8 text-textColor">
                We're transforming how businesses access and use intelligence.
                Our solutions, Data Assistant and PowerdbyAI, are designed to
                make data work for you, enabling smarter decisions and smoother
                operations.
              </p>
            </div>
            {/* <div>
              <img className="sm:max-w-[600px] sm:max-h-[600px] max-h-[500px]" src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1685397094/Group_14107_1_kgn49f.png" alt="" />
            </div> */}
          </div>
          <div className="bg-africa">
            <div className="container flex w-full flex-col lg:gap-[150px] gap-[44px]">
              <div className="flex lg:flex-row-reverse flex-col-reverse lg:gap-[5%] gap-[30px] items-center justify-between">
                <div className="lg:w-[50%] flex flex-col gap-[20px] items-start">
                  <h2 className="lg:text-[24px] font-[700]">
                    Data <span className="text-blue">Assistant:</span> Your
                    Data, Your Insights
                  </h2>
                  <p className="sm:text-[16px] text-justify leading-8 text-textColor">
                    Empower your Digital Marketing teams with AI. Data Assistant
                    is a Conversational AI solution that acts as your personal
                    campaign conversion rate optimization data assistant
                  </p>
                  <MainBtn>
                    <a
                      href="https://dataassistant.ai/"
                      target="_blank"
                      rel="noreferrer"
                      title="Data Assistant"
                    >
                      Learn more
                    </a>
                  </MainBtn>
                </div>
                <div className="lg:w-[50%] product_image flex items-center justify-center px-[42px] py-[50px]">
                  <img
                    className=""
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725470036/Frame_48096565_qsdmgz.png"
                    alt="illustrarion"
                  />
                </div>
              </div>
              <div className="flex lg:flex-row flex-col-reverse lg:gap-[5%] gap-[30px] items-center justify-between ">
                <div className="lg:w-[50%] flex flex-col gap-[20px] items-start">
                  <h2 className="lg:text-[24px] font-[700]">
                    Powerdby <span className="text-blue">[AI]</span>
                  </h2>
                  <p className="sm:text-[16px] text-justify leading-8 text-textColor">
                    Powerdby[AI] is your partner in creating and deploying AI
                    models with ease. It’s built for developers, but it's also
                    accessible to anyone who wants to harness the power of AI
                    without getting lost in the details.
                  </p>
                  <MainBtn>
                    <a
                      href="https://powerdby.ai/"
                      target="_blank"
                      rel="noreferrer"
                      title="Data Assistant"
                    >
                      Learn more
                    </a>
                  </MainBtn>
                </div>
                <div className="lg:w-[50%] product_image flex items-center justify-center">
                  <img
                    className="max-h-[600px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725470036/Frame_48096566_xcyhgr.png"
                    alt="illustrarion"
                  />
                </div>
              </div>

              <div className="flex lg:flex-row-reverse flex-col-reverse lg:gap-[5%] gap-[30px] items-center justify-between ">
                <div className="lg:w-[50%] flex flex-col gap-[20px] items-start">
                  <h2 className="lg:text-[24px] font-[700]">
                    <span className="text-blue">[AI] </span> Academy
                  </h2>
                  <p className="sm:text-[16px] text-justify leading-8 text-textColor">
                    [AI] Academy is the leading online training platform that
                    makes it easy for people to learn job-ready Data Analytics
                    and Artificial Intelligence skills. We offer a comprehensive
                    range of data and AI courses, and resources designed to meet
                    the needs of learners at every level, from beginners to
                    advanced practitioners.
                  </p>
                  <MainBtn>
                    <a
                      href="https://aiacademy.africa/"
                      target="_blank"
                      rel="noreferrer"
                      title="Data Assistant"
                    >
                      Start Learning Today
                    </a>
                  </MainBtn>
                </div>
                <div className="lg:w-[50%] product_image flex items-center justify-center py-[25px]">
                  <img
                    className="max-h-[600px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725470037/549shots_so_1_frgsjc.png"
                    alt="illustrarion"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box-ioa rounded-[16px] sm:px-[35px] px-[14px] lg:py-[17px] py-[24px] flex md:flex-row flex-col gap-[35px] lg:items-center bg-[#FFFFFF08]">
            <a
              href="https://ioaglobal.org/search-accreditation/Endorsed/Nigeria"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="max-h-[91px]"
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725471002/Frame_48096499_gkpnbf.png"
                alt="IOA"
              />
            </a>
            <img
              className="md:block hidden"
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1701154471/Vector_131_xhdycv.png"
              alt="vector"
            />
            <div className="sm:w-[70%] w-full">
              <h2 className="text-secondary font-[600] lg:text-center sm:text-start text-[14px]">
                Accreditation
              </h2>
              <p className="text-justify text-textColor text-[14px]">
                {" "}
                These short courses are innovative and align well with the
                professional and ethical standards that we promote as an
                international, not -for- profit organisation. We’re very happy
                to formally welcome representatives from the African continent
                to our global discussions for the first time. We hope that this
                will be the first of many from the region.
                <br />{" "}
                <span className="text-blue font-italic">
                  Olga Sara, Head of Education Partnerships
                </span>{" "}
              </p>
            </div>
          </div>
          <div className="container flex flex-col gap-[50px] items-center">
            <div className="flex flex-col items-center gap-[20px] text-center lg:px-[100px] max-w-[1200px] mx-auto">
              <h1 className="lg:text-[28px] ">
                Data Assistant for the Enterprise
              </h1>
              <p className="sm:text-[16px] leading-8 text-textColor">
                Transform your analytics with the power of data-driven insights
                by leveraging advanced AI technology from a conversational Data
                Assistant.
              </p>
            </div>
            <div className="w-full grid lg:grid-cols-2 gap-4 h-full">
              <div className="product_image flex lg:flex-col flex-col-reverse items-center justify-center  py-[24px]">
                <div className="px-[24px]">
                  <h2 className="font-[700] text-center sm:text-start text-[18px]">
                    Encourage a Data-Driven Culture with Data Assistant
                  </h2>
                  <p className="text-textColor text-[14p]">
                    {" "}
                    Promote the use of data to guide analysts in their
                    decision-making with the support of a dedicated Data
                    Assistant with expertise in uncovering insights and driving
                    business growth.
                  </p>
                </div>
                <img
                  className="w-full"
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725470036/384_1x_shots_so_1_bjtscm.png"
                  alt="illustrarion"
                />
              </div>
              <div className="flex flex-col gap-[40px] h-full">
                <div className="product_image h-1/2 px-[24px] pt-[24px] flex flex-col">
                  <img
                    className="w-full lg:hidden block"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1726150109/910_1x_shots_so_dqlidh.png"
                    alt="illustrarion"
                  />
                  <div className="lg:bg-analytics_help_desk h-full lg:bg-no-repeat lg:bg-cover flex flex-col lg:justify-end">
                    <h2 className="font-[700] text-center sm:text-start text-[18px]">
                      Analytics Help Desk and Ticketing
                    </h2>
                    <p className="text-textColor text-[14p]">
                      {" "}
                      Data Assistant can be your first-line support solution for
                      data and analytics requests; passes on complex questions
                      to your Analytics team for deeper analysis.
                    </p>
                  </div>
                </div>
                <div className="product_image h-1/2 px-[24px] pt-[24px] flex flex-col">
                  <img
                    className="w-full lg:hidden block"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1726150109/345_1x_shots_so_ab6cdw.png"
                    alt="illustrarion"
                  />
                  <div className="lg:bg-insight_chart h-full bg-no-repeat bg-cover flex flex-col justify-end">
                    <h2 className="font-[700] text-center sm:text-start text-[18px]">
                      Easily share Insight Across the Organisation
                    </h2>
                    <p className="text-textColor text-[14p]">
                      {" "}
                      Insights identified by your Data Assistant can be shared
                      with individual colleagues or with the entire team or
                      department to ensure everyone is focused on the same
                      insight.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#0C1D24]">
            <div className="container py-[64px] flex flex-col gap-[50px]">
              <div className="flex flex-col items-center gap-[20px] text-center lg:px-[100px]">
                <h1 className="lg:text-[40px] text-[28px] ">
                  Serving all industries
                </h1>
                <p className="sm:text-[18px] text-[16px] leading-8">
                  Unlock the power of Analytics and Artificial Intelligence for
                  all industries in Africa.
                </p>
              </div>
              <div className="grid lg:grid-cols-3 gap-[37px] justify-center">
                <div className="p-[20px] industry_card rounded-[10px] flex flex-col gap-[18px]">
                  <img
                    className=""
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725519094/Downloader-La-498714_1_vcuekt.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[12px]">
                    <h1 className="text-[16px]">Marketing & Advertising</h1>
                    <p className="text-[14px] leading-[21px]">
                      We help you track performance and gain insights to engage
                      your audience effectively and drive traffic. Our solutions
                      are designed to maximise your ROI, ensuring your marketing
                      budget delivers the best results.
                    </p>
                  </div>
                </div>
                <div className="p-[20px] industry_card rounded-[10px] flex flex-col gap-[18px]">
                  <img
                    className=""
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725519094/Downloader-La-708015_1_hau3ml.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[12px]">
                    <h1 className="text-[16px]">Retail and E-Commerce</h1>
                    <p className="text-[14px] leading-[21px]">
                      Use our AI technology to analyse data and discover key
                      customer trends and preferences. Understand what drives
                      your audience from browsing habits to purchase history.
                      Our AI enhances the customer journey, making it seamless
                      and personalised.
                    </p>
                  </div>
                </div>
                <div className="p-[20px] industry_card rounded-[10px] flex flex-col gap-[18px]">
                  <img
                    className=""
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1725519094/Downloader-La-844385_1_rjaxea.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[12px]">
                    <h1 className="text-[16px]">Fintech & Finance</h1>
                    <p className="text-[14px] leading-[21px]">
                      Predict customer behavior, assess risks accurately, and
                      personalise services with ease. Our advanced AI analyses
                      data to provide crucial insights. Ensuring compliance and
                      maintaining customer trust is vital in this industry, so
                      we prioritise data governance, including tags, cookies,
                      and privacy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container text-center mt-[40px]">
          <h2 className="text-[32px] lg:text-[40px] font-[400]">Our Customers</h2>
          <Sliders/>
          </div> */}
          <div className="bg-africa max-w-[940px] mx-auto flex flex-col gap-[44px] items-center pb-[100px]">
            <div className="container flex flex-col items-center gap-[20px] text-center lg:px-[100px] max-w-[1200px] mx-auto">
              <h1 className="lg:text-[28px] ">Frequently Asked Questions</h1>
              <p className="sm:text-[16px] leading-8 text-textColor">
                Answers to most of our frequently asked questions
              </p>
            </div>
            <Questions display={5} />
            <MainBtn>
              <Link to="/faqs">View more FAQs</Link>
            </MainBtn>
          </div>
          {/* <div className="container pb-[100px]">
            <div className="flex flex-col gap-[20px] items-center">
              <h1 className="lg:text-[40px] text-[28px] font-[400]">
                Ready for the next level?
              </h1>
              <p className="sm:text-[18px] text-[16px] text-justify leading-8">
                Join our <span className="text-blue">2000+</span> community
                of Data Scientists, Data Analysts and Data Enthusiasts
              </p>
              <a
                href="https://t.me/+OFUW6YutOewyNzk0"
                target="_blank"
                className="bg-blue rounded-[23px] py-[11px] px-[34px] text-black font-[500]"
                rel="noreferrer"
              >
                Join now
              </a>
            </div>
          </div> */}
        </div>
      </main>
      <style jsx="true">
        {`
          .gif {
            object-fit: cover;
            height: 650px;
            width: 1004px;
          }

          .bg-africa {
            background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1725556221/backgroundGradient_qbvruy.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
        `}
      </style>
    </>
  );
}
