import React from "react";

export const DislikeIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0.666829 9.33331C0.666829 9.68693 0.807305 10.0261 1.05735 10.2761C1.3074 10.5262 1.64654 10.6666 2.00016 10.6666H6.2135L5.5735 13.7133C5.56016 13.78 5.5535 13.8533 5.5535 13.9266C5.5535 14.2 5.66683 14.4533 5.84683 14.6333L6.5535 15.3333L10.9402 10.9466C11.1868 10.7 11.3335 10.3666 11.3335 9.99998V3.33331C11.3335 2.97969 11.193 2.64055 10.943 2.3905C10.6929 2.14046 10.3538 1.99998 10.0002 1.99998H4.00016C3.44683 1.99998 2.9735 2.33331 2.7735 2.81331L0.760162 7.51331C0.700162 7.66665 0.666829 7.82665 0.666829 7.99998V9.33331ZM15.3335 1.99998H12.6668V9.99998H15.3335V1.99998Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6668 9.99998V1.99998H15.3335V9.99998H12.6668ZM10.0002 1.99998C10.3538 1.99998 10.6929 2.14046 10.943 2.3905C11.193 2.64055 11.3335 2.97969 11.3335 3.33331V9.99998C11.3335 10.3666 11.1868 10.7 10.9402 10.94L6.5535 15.3333L5.84683 14.6266C5.66683 14.4466 5.5535 14.2 5.5535 13.9266L5.5735 13.7133L6.20683 10.6666H2.00016C1.64654 10.6666 1.3074 10.5262 1.05735 10.2761C0.807305 10.0261 0.666829 9.68693 0.666829 9.33331V7.99998C0.666829 7.82665 0.700162 7.66665 0.760162 7.51331L2.7735 2.81331C2.9735 2.33331 3.44683 1.99998 4.00016 1.99998H10.0002ZM10.0002 3.33331H3.98016L2.00016 7.99998V9.33331H7.86016L7.10683 12.88L10.0002 9.97998V3.33331Z"
        fill="white"
      />
    </svg>
  );
};
