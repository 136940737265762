import React from "react";

export default function MainBtn({ children, onClick }) {
  return (
    <button
      className="px-[24px] py-[10px] rounded-[100px] text-white bg-blue"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
