import Select from "react-select";
import { useEffect, useState } from "react";
import JobDetailsModal from "./JobDetailsModal";
import { toast } from "react-hot-toast";
import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

const teams = [
  { value: "Devs", label: "Devs" },
  { value: "Marketing", label: "Marketing" },
  { value: "Product", label: "Product" },
  { value: "Sales", label: "Sales" },
];
const jobLocation = [
  { value: "remote", label: "Remote" },
  { value: "usa", label: "USA" },
  { value: "lagos", label: "Lagos, Nigeria" },
  { value: "london", label: "London, UK" },
];
export default function Careers() {
  const [showModal, setShowModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [title, setTitle] = useState("");
  const [team, setTeam] = useState("");
  const [location, setLocation] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [data, setData] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);

  useEffect(() => {
    fetch(`https://cms.analyticsintelligence.ai/api/job-lists`)
      .then((res) => res.json())
      .then((jobs) => {
        setData(jobs.data);
        const titles = jobs.data.map((t) => ({
          value: t.title,
          label: t.title,
        }));
        setJobTitles(titles);
      });
  }, []);

  useEffect(() => {
    let filteredResult = [];
    let formattedTitle = "";
    // This check is to handle and correctly format user input before filtering the search
    if (title) {
      const words = title.split(" ");
      const formattedWords = words.map((w) => {
        if (w !== "") return w[0].toUpperCase() + w.substring(1).toLowerCase();
        return null;
      });
      formattedTitle = formattedWords.join(" ");
    }
    if (title && !team && !location) {
      filteredResult =
        data && data.filter((data) => data.title.includes(formattedTitle));
      setSearchData(filteredResult);
    } else if (location && !title && !team) {
      filteredResult =
        data && data.filter((data) => data.location === location);
      setSearchData(filteredResult);
    } else if (team && !location && !title) {
      filteredResult = data && data.filter((data) => data.team === team);
      setSearchData(filteredResult);
    } else if (location && title && !team) {
      filteredResult =
        data &&
        data.filter(
          (data) =>
            data.location === location && data.title.includes(formattedTitle)
        );
      setSearchData(filteredResult);
    } else if (location && !title && team) {
      filteredResult =
        data &&
        data.filter((data) => data.location === location && data.team === team);
      setSearchData(filteredResult);
    } else if (!location && title && team) {
      filteredResult =
        data &&
        data.filter(
          (data) => data.team === team && data.title.includes(formattedTitle)
        );
      setSearchData(filteredResult);
    } else if (location && title && team) {
      filteredResult =
        data &&
        data.filter(
          (data) =>
            data.team === team &&
            data.title.includes(formattedTitle) &&
            data.location === location
        );
      setSearchData(filteredResult);
    } else {
      setSearchData([]);
    }
  }, [title, team, location, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    if (!team) {
      setTeamError(true);
    } else {
      setTeamError(false);
    }
    if (!location) {
      setLocationError(true);
    } else {
      setLocationError(false);
    }
    if (title && team && location) {
      setTitleError(false);
      setTeamError(false);
      setLocationError(false);
      // This check is to handle and correctly format user input before filtering the search
      const words = title.split(" ");
      const formattedWords = words.map((w) => {
        if (w !== "") return w[0].toUpperCase() + w.substring(1).toLowerCase();
        return null;
      });
      const formattedTitle = formattedWords.join(" ");

      const searchResult =
        data &&
        data.filter(
          (data) =>
            data.title.includes(formattedTitle) &&
            data.team === team &&
            data.location === location
        );
      if (searchResult.length === 0) {
        toast.error("No job available for this search entries", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
      setSearchData(searchResult);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleShowModal = (id) => {
    setShowModal(true);
    scrollToTop();
    const filtered = data && data.filter((data) => data.id === id);
    setFilteredData(filtered);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          Join Our AI Revolution: Explore Career Opportunities at Analytics
          Intelligence
        </title>
        <link rel="canonical" href="/careers/" />
        <meta
          name="description"
          content="[AI] Analytics Intelligence is a data analytics and artificial intelligence software development company. We believe that the growth of data, data analysis and artificial intelligence, are significantly changing how organizations, cities and government agencies will be run going into the future and are providing services and products to help clients implement future proof Digital Transformation strategy."
        />
        <meta
          name="keywords"
          content="AI, Analytics Intelligence, data analytics, artificial intelligence, software development company, careers, growth of data, data analysis, changing organizations, cities, government agencies, future, services, products, implement, Digital Transformation strategy"
        />
      </Helmet>
      <div className="">
        {showModal && (
          <div className="absolute top-0 left-0 z-20 bg-gray-900 opacity-75 w-full h-full"></div>
        )}
        <div className="banner">
          <div className="container">
            <div className="house text-center">
              <h1>[AI] Careers</h1>
              <p className="leading-8">
                [AI] Analytics Intelligence is a data analytics and artificial
                intelligence software development company. We believe that the
                growth of data, data analysis and artificial intelligence, are
                significantly changing how organizations, cities and government
                agencies will be run going into the future and are providing
                services and products to help clients implement future proof
                Digital Transformation strategy.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center pb-[60px]">
          <div className="flex flex-col items-center mt-[60px] text-blue max-w-[1300px] w-[90%] max-auto gap-[60px]">
            <h2 className="md:text-[45px] text-[30px] font-[400] text-white">
              Join our team
            </h2>
            <form
              className="flex flex-col md:flex-row gap-[28px] rounded-[20px] shadow-box py-[28px] px-[23px] w-full bg-[#000000] text-black"
              onSubmit={handleSubmit}
            >
              <label className="relative md:w-[30%] w-full">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "40px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      borderRadius: "10px",
                      borderColor: titleError ? "red" : "#C4C4C4",
                      width: "100%",
                    }),
                  }}
                  options={jobTitles}
                  placeholder="Title"
                  onChange={(e) => setTitle(e.label)}
                />
                <img
                  className="absolute left-[15px] top-[30%]"
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678175310/Vector_14_tvwqv6.svg"
                  alt="search-icon"
                />
              </label>
              <label className="relative md:w-[30%] w-full">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "40px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      borderRadius: "10px",
                      borderColor: teamError ? "red" : "#C4C4C4",
                      width: "100%",
                    }),
                  }}
                  options={teams}
                  placeholder="Team"
                  onChange={(e) => setTeam(e.label)}
                />
                <img
                  className="absolute left-[15px] top-[20%]"
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678175318/Vector_15_gp5d9l.svg"
                  alt="briefcase-icon"
                />
              </label>
              <label className="relative md:w-[30%] w-full">
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "40px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      borderRadius: "10px",
                      borderColor: locationError ? "red" : "#C4C4C4",
                      width: "100%",
                    }),
                  }}
                  options={jobLocation}
                  placeholder="Location"
                  onChange={(e) => setLocation(e.label)}
                />
                <img
                  className="absolute left-[15px] top-[20%]"
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1678175327/Vector_16_uo268c.svg"
                  alt="location-icon"
                />
              </label>
              <button className="bg-blue py-[10px] md:w-[10%] w-full rounded-[10px] text-black font-[500] hover:opacity-70">
                Search
              </button>
            </form>
            <div className="flex flex-col gap-[50px] w-full">
              {searchData.length === 0 &&
                data &&
                data.map((job) => (
                  <div
                    key={job.id}
                    className="flex flex-col sm:flex-row justify-between shadow-box py-[28px] px-[23px] rounded-[20px] sm:items-center gap-[30px] sm:gap-0 bg-[#000000]"
                  >
                    <div className="sm:w-[70%] w-full">
                      <h3 className="text-[28px] font-[400]">{job.title}</h3>
                      <p className="my-[12px] text-justify text-white leading-8">
                        {job.summary}
                      </p>
                      <div className="flex gap-[10px]">
                        <span className="text-black rounded-[10px] bg-blue py-[2.5px] px-[15px]">
                          {job.team}
                        </span>
                        <span className="text-black rounded-[10px] bg-blue py-[2.5px] px-[15px]">
                          {job.location}
                        </span>
                      </div>
                    </div>
                    <button
                      className="py-[10px] px-[26px] border border-[1px] border-blue rounded-[12px] hover:bg-blue hover:text-black transition "
                      onClick={() => handleShowModal(job.id)}
                    >
                      View more
                    </button>
                  </div>
                ))}
              {searchData.length > 0 &&
                searchData.map((job) => (
                  <div
                    key={job.id}
                    className="flex flex-col sm:flex-row justify-between shadow-box py-[28px] px-[23px] rounded-[20px] sm:items-center gap-[30px] sm:gap-0 bg-[#000000]"
                  >
                    <div className="sm:w-[70%] w-full">
                      <h3 className="text-[28px] font-[400]">{job.title}</h3>
                      <p className="my-[12px] text-justify text-white leading-8">
                        {job.summary}
                      </p>
                      <div className="flex gap-[10px]">
                        <span className="text-white rounded-[10px] bg-blue py-[2.5px] px-[15px]">
                          {job.team}
                        </span>
                        <span className="text-white rounded-[10px] bg-blue py-[2.5px] px-[15px]">
                          {job.location}
                        </span>
                      </div>
                    </div>
                    <button
                      className="py-[10px] px-[26px] border border-[1px] border-blue rounded-[12px] hover:bg-blue hover:text-black transition "
                      onClick={() => handleShowModal(job.id)}
                    >
                      View more
                    </button>
                  </div>
                ))}
            </div>
            {showModal && (
              <JobDetailsModal data={filteredData} close={handleClose} />
            )}
          </div>
        </div>
        <style jsx="true">
          {`
            .shadow-box {
              box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
            }
            .content-icon {
              font-size: 40px;
              margin-bottom: 1rem;
            }
            .cards-host {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
              grid-gap: 2rem;
              margin-top: 2rem;
              justify-content: center;
            }
            .content-card {
              background-color: #fff;
              border-radius: 10px;
              padding: 1.5rem;
              color: #1f3569;
            }
            .content-card h3 {
              font-size: 1.3rem;
              margin-bottom: 1rem;
            }
            .cards {
              background-color: #1f3569;
              padding-top: min(4rem, 10vw);
              padding-bottom: min(4rem, 10vw);
              color: #fff;
            }
            .other {
              text-align: center;
              color: #1f3569;
            }
            .banner .title {
              margin-bottom: 2rem;
              color: rgba(31, 53, 105, 1);
            }
            .images {
              display: grid;
              grid-template-columns: repeat(auto-fit, 200px);
              grid-gap: 2rem;
              grid-auto-rows: minmax(170px, auto);
              padding-top: 3rem;
              padding-bottom: 3rem;
              justify-content: center;
            }

            .scroll .scroll-img {
              background-color: #fff;
              color: #1f3569;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              padding: 1rem;
            }
            .scroll .scroll-img p {
              font-weight: 600;
              margin-top: 1rem;
            }
            .scroll-img {
              background-color: #fff;
              color: #1f3569;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 1rem;
            }
            .icon {
              font-size: 100px;
              color: #1f3569 !important;
            }
            .detection {
              padding-top: min(4rem, 10vw);
            }

            .text-body {
              color: #000;
            }
            .grid {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
              justify-content: center;

              grid-gap: 2rem;
            }
            .card-base {
              display: flex;
              align-items: center;

              margin-top: 2rem;
              flex-wrap: wrap;
              justify-content: space-around;
              margin-top: 1.5rem;
              margin-bottom: min(6rem, 12vw);
              margin-top: 3rem;
            }
            .card-base h4 {
              font-size: 1.4rem;
              font-weight: 800;
              color: #1f3569;
            }
            .card-base a {
              color: inherit;
              text-decoration: none;
            }
            .card-base p {
              margin-top: 1rem;
              margin-bottom: 1rem;
              font-size: 1.1rem;
            }
            .card-base .content {
              background-color: #fff;

              flex-grow: 1;
              flex-basis: 300px;
              max-width: 100%;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 1px 1px 0px;
            }
            .card-base .gif {
              object-fit: cover;
              max-height: 450px;
              flex-grow: 1;
            }
            @media screen and (min-width: 875px) {
              .card-base:nth-of-type(even) {
                flex-direction: row-reverse;
              }
              .card-base .content {
                max-width: 350px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              }

              .card-base .gif {
                object-fit: cover;
                max-width: 600px;
              }
            }

            .card-base .btn-base {
              padding: 1rem 4rem;
              color: #fff;
              margin-top: 1.5rem;
              display: inline-block;
            }
            .products {
              background-color: #fff;
              padding-top: 5rem;
              padding-bottom: 5rem;
              color: #1f3569;
            }
            .deliver {
              color: #1f3569;
              padding-top: min(6rem, 10vw);
              padding-bottom: min(6rem, 15vw);
            }
            .both {
              display: grid;
              grid-template-columns: 3fr 2fr;
              align-items: center;
              justify-content: center;
            }
            .both-media {
              padding: 2rem;
            }
            .both-media img {
              object-fit: contain;
              border-radius: 20px;
              max-width: 100%;
            }
            @media only screen and (max-width: 800px) {
              .both {
                grid-template-columns: 1fr;
              }
            }
            .both-text p {
              font-size: 1.3rem;
            }
            .deliver h2 {
              margin-bottom: 1rem;
              font-size: 1.9rem;
            }
            .banner {
              margin-top: 85px;
              background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png");
              padding-top: min(8rem, 10vw);
              padding-bottom: min(10rem, 15vw);
              background-repeat: no-repeat;
              background-size: cover;
              color: #fff;
            }
            .house h1 {
              font-size: 2.2rem;
            }
            .house p {
              font-size: 1.2rem;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
            .btn-group button {
              padding: 1rem 4rem;
              margin-bottom: 1rem;
              font-size: 1.2rem;
            }
            .btn-group .btn-white {
              margin-right: 1rem;
            }
            .btn-base {
              padding: 1rem 4rem;
              margin-bottom: 1rem;
              font-size: 1.2rem;
              margin-top: 1rem;
            }
          `}
        </style>
      </div>
    </>
  );
}
