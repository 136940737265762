import React from "react";

export const ChatIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33317 15H4.99984V18.4008L9.25067 15H13.3332C14.2523 15 14.9998 14.2525 14.9998 13.3333V6.66667C14.9998 5.7475 14.2523 5 13.3332 5H3.33317C2.414 5 1.6665 5.7475 1.6665 6.66667V13.3333C1.6665 14.2525 2.414 15 3.33317 15Z"
        fill="white"
      />
      <path
        d="M16.6667 1.66669H6.66667C5.7475 1.66669 5 2.41419 5 3.33335H15C15.9192 3.33335 16.6667 4.08085 16.6667 5.00002V11.6667C17.5858 11.6667 18.3333 10.9192 18.3333 10V3.33335C18.3333 2.41419 17.5858 1.66669 16.6667 1.66669Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33317 15H4.99984V18.4008L9.25067 15H13.3332C14.2523 15 14.9998 14.2525 14.9998 13.3333V6.66667C14.9998 5.7475 14.2523 5 13.3332 5H3.33317C2.414 5 1.6665 5.7475 1.6665 6.66667V13.3333C1.6665 14.2525 2.414 15 3.33317 15Z"
        fill="white"
      />
      <path
        d="M16.6667 1.66669H6.66667C5.7475 1.66669 5 2.41419 5 3.33335H15C15.9192 3.33335 16.6667 4.08085 16.6667 5.00002V11.6667C17.5858 11.6667 18.3333 10.9192 18.3333 10V3.33335C18.3333 2.41419 17.5858 1.66669 16.6667 1.66669Z"
        fill="white"
      />
    </svg>
  );
};
