import { Helmet, HelmetData } from "react-helmet-async";
const helmetData = new HelmetData({});

export default function About() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Analytics Intelligence Africa | How we started out</title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/"
        />
        <meta
          name="description"
          content="The 21st-century human lives at the intersection of data and technology. At [AI] Analytics Intelligence, we believe the world would be more inclusive if everyone had simple, quicker access to insights generated via data."
        />
        <meta
          name="keywords"
          content="data and technology, inclusive, simple access, quicker access, insights, data analytics, artificial intelligence, software development company, empowering businesses, Africa, unique challenges, opportunities, African market, cutting-edge solutions, specific needs, digital transformation, trusted partner, data's potential, informed decisions, growth, sustainable development, revolutionizing, data utilization, innovative analytics, AI solutions, empowering companies, compete globally, economic development"
        />
      </Helmet>
      <div className="bg-africa">
        <div className="container flex flex-col md:gap-[100px] gap-[50px] md:pt-[200px] pt-[150px] pb-[150px] md:px-[50px]  text-white">
          <div className="flex flex-col gap-[20px] items-center justify-center">
            <span className="px-[24px] py-[10px] rounded-[100px] text-white border-blue border">
              About us
            </span>
            <h1 className="md:text-[38px] text-[24px] font-[800] text-center">
              Making Analytics and Artificial Intelligence Accessible to All
            </h1>
            <p className="text-center text-textColor font-[400] md:leading-[35px] leading-[21px] md:text-[16px] text-[14px]">
              We are a data analytics and artificial intelligence software
              development company, dedicated to harnessing the power of AI to
              drive transformative changes across various industries, including
              financial, telecommunications, and government organisations. Our
              product portfolio, including Data Assistant and Powerdby [AI],
              reflects our mission to make data securely accessible to all,
              catering to the unique needs of various sectors.
            </p>
            <img
              className="md:w-[80%] mx-auto mt-[40px]"
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687423369/_MG_0286_1_kppe31.png"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-[15px]">
            <h2 className="md:text-[28px] text-[18px] font-[700]">
              Origins And Purpose
            </h2>
            <div className="flex flex-col gap-[20px]">
              <p className="text-justify text-textColor md:leading-[35px] leading-[21px] md:text-[16px] text-[14px]">
                Our journey began in 2011, driven by the recognition that
                businesses increasingly needed to interpret digital data for
                informed decision-making, yet the market faced a shortage of
                skilled digital analysts. To address this, we delved into
                machine learning and natural language processing systems to
                harness AI for communicating data insights through conversation.
                By 2018, we launched our MVP, a data pipeline, and an
                intelligent middle-layer application that connects to multiple
                data sources, interprets, and communicates analysis through a
                conversational AI interface, empowering informed
                decision-making.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[15px]">
            <h2 className="md:text-[28px] text-[18px] font-[700]">
              Our Mission
            </h2>
            <div className="flex flex-col gap-[20px]">
              <p className="text-justify text-textColor md:leading-[35px] leading-[21px] md:text-[16px] text-[14px]">
                Our mission is to democratise data insight and empower
                organisations of all sizes to become data-driven. We are
                committed to revolutionising how businesses harness AI and data
                analytics. Through our innovative AI solutions, we strive to
                make complex data understandable and actionable for everyone,
                fostering a culture of collaboration, efficiency, and informed
                decision-making.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[15px]">
            <h2 className="md:text-[28px] text-[18px] font-[700]">
              Safety & Responsibility
            </h2>
            <div className="flex flex-col gap-[20px]">
              <p className="text-justify text-textColor md:leading-[35px] leading-[21px] md:text-[16px] text-[14px]">
                At [AI] Analytics Intelligence, safety and responsibility are
                core values guiding our work in generative AI, data analytics,
                and artificial intelligence. We prioritise rigorous testing,
                transparency, user privacy, and ongoing dialogue with experts
                and stakeholders, which is one of the reasons we developed our
                PLLM product. Our commitment extends to proactive measures for
                risk mitigation, comprehensive team training, and advocacy for
                ethical standards and regulations in AI. We strive to foster
                trust, empower users, and contribute positively to the broader
                AI safety research agenda, all with the aim of creating a future
                where AI enhances human well-being responsibly.
              </p>
            </div>
          </div>
          <style jsx="true">
            {`
              // .bg-header{
              //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
              //   background-repeat: no-repeat;
              //   background-size: 100%;
              //   background-position: center;
              // }
            `}
          </style>
        </div>
      </div>
    </>
  );
}
