import { useState } from "react";

export default function Questions({ display }) {
  const [currentTab, setCurrentTab] = useState("");
  const data = [
    {
      title: "What service does [AI] offer?",
      body: "We provide AI and data solutions, including Data Assistant for analytics and Powerdby[AI] for AI model creation. We also offer training through [AI] Academy",
    },
    {
      title: "How can [AI] help my business make better decisions?",
      body: "Our Data Assistant tool provides tailored insights, helping you make smarter, data-driven decisions quickly and easily",
    },
    {
      title: "I'm not a tech expert. Can I still use AI in my business?",
      body: "Absolutely! Our Powerdby[AI] platform is designed for everyone, making AI model creation simple and accessible, even for non-technical users",
    },
    {
      title: "How can I learn data analytics and AI skills quickly?",
      body: "[AI] Academy offers easy-to-follow courses that get you job-ready in data analytics and AI, no matter your current skill level",
    },
    {
      title: "How can I ensure my team uses data effectively?",
      body: "Data Assistant encourages a data-driven culture by guiding your team with actionable insights and supporting analytics requests",
    },
    {
      title: "Which industries benefit most from [AI]'s solutions?",
      body: "We specialise in Marketing, Retail, E-Commerce, Fintech, and any other sectors looking to make data-driven decisions",
    },
    {
      title: "How can I promote data-driven decision in my company?",
      body: "Use Data Assistant to transform your enterprise’s approach to data, making insights easy to access and share across your organisation",
    },
    {
      title: "Can I trust [AI] with my company’s data?",
      body: "Yes, we prioritise data governance and compliance, ensuring your data is secure and used responsibly",
    },
    {
      title: "Why should I choose [AI] over other AI providers?",
      body: "We make AI easy, practical, and powerful, providing solutions that are designed to meet your specific business needs",
    },
    {
      title: "How can I partner with [AI]?",
      body: "Request a consultation on our website to discuss how we can collaborate to drive your business forward with AI and data solutions",
    },
    {
      title: "How do I get started with [AI]?",
      body: "Start by booking a demo or requesting a consultation through our website. We’re here to help you unlock the full potential of your data",
    },
  ];
  return (
    <>
      <div className="container is-max-widescreen p-2">
        <div className="faqs">
          {data.slice(0, display || data.length).map((d) => (
            <div
              className="expandable"
              key={d.title}
              onClick={() => {
                if (currentTab === d.title) {
                  return setCurrentTab("");
                }
                setCurrentTab(d.title);
              }}
            >
              <div className="expandable-header">
                <p className="text-[18px] text-primary font-[600]">{d.title}</p>
                <p
                  className={`${
                    currentTab === d.title ? "rotate-180" : "rotate-0"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-chevron-down"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </p>
              </div>
              <p
                className={` ${
                  currentTab === d.title ? "expand" : ""
                } expandable-body text-textColor`}
              >
                {d.body}
              </p>
            </div>
          ))}
        </div>
      </div>
      <style jsx="true">
        {`
          .feature {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
          }
          .number {
            background: transparent;
          }
          .feature span {
            min-width: 79px;
            max-width: 79px;
            height: 79px;
            border-radius: 50%;
            background: #1a8aff;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 40px;
          }
          .feature-content {
            width: 80%;
          }
          .features-list {
            display: flex;
            flex-direction: column;
            gap: 88px;
          }
          .features {
            margin-top: 100px;
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            gap: 96px;
          }
          .header {
            display: flex;
            flex-direction: column;
            gap: 44px;
            align-items: center;
          }
          .header-text {
            color: #2f4171;
            text-align: center;
          }
          .header-text span {
            color: #1a8aff;
          }
          .tip {
            padding: 1rem;
          }
          .price-list li::before {
            content: "";
            display: inline-block;
            position: absolute;
            left: 1px;
            top: 2px;
            height: 15px;
            width: 25px;
            background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1675685567/Check_ivrwsx.png");
            background-position: left 5px;
            background-repeat: no-repeat;
            background-size: 15px;
          }
          .price-list li {
            padding-bottom: 1rem;
            padding-left: 25px;
            position: relative;
          }
          .choose {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-gap: 2rem;
            justify-content: space-between;
          }

          .choose-card {
            background-color: #fff;
            border-radius: 20px;
            padding: 2rem;
            box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
          }
          .choose-card p {
            font-size: 0.9rem;
          }

          .choose-card.growth {
            background-color: var(--main-orange);
            color: #fff;
          }
          .expandable-body {
            height: 0px;
            overflow: hidden;
            transition: 0.3s ease;
            margin-bottom: 2rem;
            line-height: 1.6;
          }
          .expandable-body.expand {
            height: auto;
            margin-top: 0.8rem;
          }
          .expandable {
            transition: 0.3s ease;
            cursor: pointer;
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
          }
          .expandable-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding-top: 1rem;
          }
          .home {
            margin-top: 65px;
          }
          .expandable-header > div {
            flex-basis: 100px;
          }
          .expandable-header .icon {
            margin-left: 1rem;
            flex-shrink: 0;
          }
        `}
      </style>
    </>
  );
}
