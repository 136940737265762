import { Helmet, HelmetData } from "react-helmet-async";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { client } from "../../../client";
const helmetData = new HelmetData({});

export default function AllBlogs() {
  const [posts, setPosts] = useState([]);
  const [featuredPost, setFeaturedPost] = useState([]);
  const [sortedPosts, setSortedPosts] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");
  const [searchPage, setSearchPage] = useState(false);

  useEffect(() => {
    setSortedPosts(posts?.sort((a, b) => b.id - a.id));
  }, [posts]);
  // useEffect(()=>{
  //   fetch(`https://cms.analyticsintelligence.ai/api/mainsite-blogs`)
  //   .then(res => res.json())
  //   .then(post => {
  //     setPosts(post.data);
  //     post.data.forEach(p => {
  //       if(p.featured_post){
  //         setFeaturedPost(p)
  //       }
  //     })
  //   })
  // },[])
  useEffect(() => {
    client
      .fetch(
        `*[_type == "Title"]{
      title,
      author,
      body,
      author_avatar,
      cover_photo,
      tags,
      published_date,
      featured_post,
      id,
      is_africa
    }`
      )
      .then((data) => {
        setPosts(data?.filter((p) => p?.is_africa));
        data?.forEach((p) => {
          if (p?.featured_post && p?.is_africa) {
            setFeaturedPost(p);
          }
        });
      })
      .catch(console.error);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (search) {
      setSearchPage(true);
    }
    function capitalizeSentence(search) {
      const words = search?.split(" ");
      const capitalizedWords = words?.map(function (word) {
        return word?.charAt(0)?.toUpperCase() + word?.slice(1);
      });
      const capitalizedSentence = capitalizedWords.join(" ");
      return capitalizedSentence;
    }
    const modified = capitalizeSentence(search);
    setSearchResult(
      posts.filter(
        (p) => p?.title?.includes(search) || p?.title?.includes(modified)
      )
    );
  };
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Blog</title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/blog"
        />
        <meta
          name="description"
          content="The future is here, and it's powered by AI. Our blog is dedicated to uncovering cutting-edge research, innovative startups, and game-changing applications of artificial intelligence across industries."
        />
        <meta
          name="keywords"
          content="future, AI, powered by AI, blog, cutting-edge research, innovative startups, game-changing applications, artificial intelligence, industries"
        />
      </Helmet>
      <main className="flex flex-col text-white flex flex-col pb-[150px]">
        <div className="">
          <div className="container pt-[200px] w-full flex flex-col items-center gap-[40px] justify-center pb-[50px]">
            <div className=" flex flex-col gap-[20px] text-center">
              <h1 className="md:text-[55px] text-[32px] font-[400]">Blog</h1>
              <p className="sm:text-[18px] text-[16px] leading-8">
                The future is here, and it's powered by AI. Our blog is
                dedicated to uncovering cutting-edge research, innovative
                startups, and game-changing applications of artificial
                intelligence across industries.
              </p>
            </div>
            <form
              className="relative lg:w-[35%] md:w-[60%] w-full mx-auto"
              onSubmit={handleSubmit}
            >
              <label className="">
                <input
                  type="text"
                  className="w-full pl-[70px] px-[20px] py-[15px] rounded-[33px] bg-[#FFFFFF] bg-opacity-10 outline-none"
                  placeholder="Search post"
                  required
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </label>
              <button className="absolute px-[20px] py-[15px] rounded-[33px] bg-blue outline-none text-white right-0 font-[500]">
                Search
              </button>
              <div className="absolute left-0 px-[20px] py-[15px] top-0">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686912196/Vector_2_stf633.png"
                  alt="icon"
                />
              </div>
            </form>
          </div>
        </div>
        {!searchPage && (
          <div className="flex flex-col gap-[45px]">
            <h2 className="font-[400] md:text-[36px] text-[25px] text-center">
              All posts
            </h2>
            {featuredPost?.id && (
              <div className="container">
                <Link
                  reloadDocument
                  to={`/blog/${featuredPost?.id}`}
                  className="px-[50px] py-[20px] featured h-[605px] flex flex-col justify-end rounded-[10px]"
                >
                  <div className="flex flex-col gap-[10px]">
                    <h4 className="font-[400] md:text-[20px] text-[16px]">
                      Featured
                    </h4>
                    <h3 className="font-[400] md:text-[40px] text-[25px]">
                      {featuredPost?.title}
                    </h3>
                    <ReactMarkdown
                      children={
                        featuredPost?.body?.split(" ")?.slice(0, 30).join(" ") +
                        (featuredPost?.body?.split(" ")?.length > 10
                          ? "..."
                          : "")
                      }
                    />
                  </div>
                </Link>
              </div>
            )}
            <span className="container font-[400] text-[32px]">
              Recent blog posts
            </span>
            <div className="container flex flex-wrap gap-[30px] justify-center">
              {sortedPosts &&
                featuredPost &&
                sortedPosts.map((post) =>
                  post?.id !== featuredPost?.id ? (
                    <Link
                      reloadDocument
                      to={`/blog/${post.id}`}
                      className="lg:w-[31.5%] md:w-[48%] w-full bg-[#242424] rounded-[10px] p-[13px]"
                      key={post?.id}
                    >
                      <div className="max-h-[200px] overflow-hidden">
                        <img
                          src={post?.cover_photo}
                          alt="event"
                          className="brand"
                        />
                      </div>
                      <div className="flex flex-col gap-[12px] justify-between min-h-[200px] mt-[24px]">
                        <h3 className="font-[400] md:text-[22px] text-[20px]">
                          {post?.title}
                        </h3>
                        <div className="flex flex-col gap-[20px]">
                          <div className="flex">
                            <ReactMarkdown
                              children={
                                post?.body?.split(" ").slice(0, 10).join(" ") +
                                (post?.body?.split(" ").length > 10
                                  ? "..."
                                  : "")
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <div className="flex items-center gap-[10px]">
                              <div className="flex items-center gap-[5px]">
                                <img
                                  className="object-cover rounded-[50%] h-[44px] w-[44px] border border-[3px] border-[#F3F3F3]"
                                  src={post?.author_avatar}
                                  alt="avatar"
                                />
                                <span>{post?.author}</span>
                              </div>
                              <p>
                                {new Date(
                                  post?.published_date
                                ).toLocaleDateString("en-US", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    ""
                  )
                )}
            </div>
          </div>
        )}
        {searchPage && (
          <div className="bg-africa">
            <div className="flex justify-center">
              <button
                className="px-[20px] py-[15px] rounded-[33px] bg-blue outline-none text-white` right-0 font-[500] mb-[40px]"
                onClick={() => {
                  setSearchPage(false);
                  setSearch("");
                }}
              >
                Back
              </button>
            </div>
            <div className="container flex flex-wrap gap-[30px] justify-center">
              {searchResult &&
                searchResult.map((post) => (
                  <Link
                    reloadDocument
                    to={`/blog/${post?.id}`}
                    className="lg:w-[31.5%] md:w-[48%] w-full bg-[#242424] rounded-[10px] p-[13px]"
                    key={post?.id}
                  >
                    <div className="max-h-[200px] overflow-hidden">
                      <img
                        src={post?.cover_photo}
                        alt="event"
                        className="brand"
                      />
                    </div>
                    <div className="flex flex-col gap-[12px] justify-between min-h-[200px] mt-[24px]">
                      <h3 className="font-[400] md:text-[22px] text-[20px]">
                        {post?.title}
                      </h3>
                      <div className="flex flex-col gap-[20px]">
                        <div className="flex">
                          <ReactMarkdown
                            children={
                              post.body.split(" ").slice(0, 10).join(" ") +
                              (post.body.split(" ").length > 10 ? "..." : "")
                            }
                          />
                        </div>
                        <div className="flex justify-between">
                          <div className="flex items-center gap-[10px]">
                            <div className="flex items-center gap-[5px]">
                              <img
                                className="object-cover rounded-[50%] h-[44px] w-[44px] border border-[3px] border-[#F3F3F3]"
                                src={post?.author_avatar}
                                alt="avatar"
                              />
                              <span>{post?.author}</span>
                            </div>
                            <p>
                              {new Intl.DateTimeFormat("en-GB", {
                                dateStyle: "long",
                                weekday: undefined,
                              }).format(new Date(post?.published_date))}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              {searchResult.length === 0 && (
                <div className="text-[36px]"> No Match Found </div>
              )}
            </div>
          </div>
        )}
      </main>
      <style jsx="true">
        {`
          .gif {
            object-fit: cover;
            height: 650px;
            width: 1004px;
          }
          .bgg {
            background: linear-gradient(
                to bottom,
                rgba(90, 203, 245, 0.1),
                rgba(90, 203, 245, 0.1)
              ),
              url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1686660448/Line_2_yjmzxh.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
          .bg-africa {
            background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1725556221/backgroundGradient_qbvruy.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
          .featured {
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.7) 82.06%
              ),
              url(${featuredPost ? featuredPost.cover_photo : ""});
            background-repeat: no-repeat;
            background-size: cover;
          }
        `}
      </style>
    </>
  );
}
