import React from "react";

export const CallIcon = ({ active = false }) => {
  return active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4166 11.1325L13.7966 10.755C14.3216 10.2333 15.1391 10.1258 15.8107 10.4908L17.4024 11.3567C18.4249 11.9125 18.6507 13.275 17.8516 14.0692L16.6682 15.2458C16.3666 15.5458 15.9932 15.7642 15.5632 15.8042C14.5699 15.8967 12.5257 15.8467 10.1141 14.435L13.4166 11.1325ZM8.49157 6.23584L8.73073 5.9975C9.3199 5.4125 9.37573 4.4725 8.86157 3.78584L7.81157 2.38334C7.1749 1.53334 5.94573 1.42084 5.21657 2.14584L3.90823 3.44584C3.5474 3.80584 3.30573 4.27084 3.3349 4.7875C3.38907 5.7375 3.7124 7.47084 5.12573 9.60084L8.49157 6.23584Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M10.0526 9.58082C7.59764 7.13999 8.48598 6.24082 8.49181 6.23499L5.12598 9.60165C5.68181 10.4383 6.40514 11.3358 7.34598 12.2717C8.29598 13.2167 9.22931 13.9175 10.1143 14.435L13.4168 11.1333C13.4168 11.1333 12.5151 12.03 10.0526 9.58165"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4166 11.1325L13.7966 10.755C14.3216 10.2333 15.1391 10.1258 15.8107 10.4908L17.4024 11.3567C18.4249 11.9125 18.6507 13.275 17.8516 14.0692L16.6682 15.2458C16.3666 15.5458 15.9932 15.7642 15.5632 15.8042C14.5699 15.8967 12.5257 15.8467 10.1141 14.435L13.4166 11.1325ZM8.49157 6.23584L8.73073 5.9975C9.3199 5.4125 9.37573 4.4725 8.86157 3.78584L7.81157 2.38334C7.1749 1.53334 5.94573 1.42084 5.21657 2.14584L3.90823 3.44584C3.5474 3.80584 3.30573 4.27084 3.3349 4.7875C3.38907 5.7375 3.7124 7.47084 5.12573 9.60084L8.49157 6.23584Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M10.0526 9.58082C7.59764 7.13999 8.48598 6.24082 8.49181 6.23499L5.12598 9.60165C5.68181 10.4383 6.40514 11.3358 7.34598 12.2717C8.29598 13.2167 9.22931 13.9175 10.1143 14.435L13.4168 11.1333C13.4168 11.1333 12.5151 12.03 10.0526 9.58165"
        fill="white"
      />
    </svg>
  );
};
