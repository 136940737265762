export default function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.69211 11.897L9.10211 12.367C10.0341 12.677 10.4991 12.833 10.8331 13.167C11.1671 13.501 11.3231 13.967 11.6331 14.897L12.1031 16.307C12.8871 18.661 13.2791 19.837 14.0001 19.837C14.7201 19.837 15.1131 18.661 15.8971 16.307L18.7351 7.79499C19.2871 6.13899 19.5631 5.31099 19.1261 4.87399C18.6891 4.43699 17.8611 4.71299 16.2061 5.26399L7.69111 8.10399C5.34011 8.88699 4.16211 9.27899 4.16211 9.99999C4.16211 10.721 5.33911 11.113 7.69211 11.897Z"
        fill="#01ADFF"
        fill-opacity="0.25"
      />
      <path
        d="M7.69211 11.897L9.10211 12.367C10.0341 12.677 10.4991 12.833 10.8331 13.167C11.1671 13.501 11.3231 13.967 11.6331 14.897L12.1031 16.307C12.8871 18.661 13.2791 19.837 14.0001 19.837C14.7201 19.837 15.1131 18.661 15.8971 16.307L18.7351 7.79499C19.2871 6.13899 19.5631 5.31099 19.1261 4.87399C18.6891 4.43699 17.8611 4.71299 16.2061 5.26399L7.69111 8.10399C5.34011 8.88699 4.16211 9.27899 4.16211 9.99999C4.16211 10.721 5.33911 11.113 7.69211 11.897Z"
        fill="#01ADFF"
        fill-opacity="0.25"
      />
      <path
        d="M9.5259 13.842L7.4639 13.155C7.31961 13.1069 7.16625 13.0924 7.01549 13.1125C6.86473 13.1326 6.72054 13.1868 6.5939 13.271L5.5039 13.997C5.37487 14.083 5.2736 14.2046 5.2124 14.347C5.15121 14.4895 5.13273 14.6467 5.15922 14.7994C5.1857 14.9522 5.25601 15.094 5.36159 15.2075C5.46716 15.3211 5.60346 15.4015 5.7539 15.439L7.7089 15.927C7.79687 15.949 7.87722 15.9944 7.94134 16.0586C8.00546 16.1227 8.05093 16.203 8.0729 16.291L8.5609 18.246C8.59841 18.3964 8.67883 18.5327 8.79238 18.6383C8.90592 18.7439 9.0477 18.8142 9.20047 18.8407C9.35323 18.8672 9.5104 18.8487 9.65286 18.7875C9.79532 18.7263 9.91693 18.625 10.0029 18.496L10.7289 17.406C10.8131 17.2794 10.8673 17.1352 10.8874 16.9844C10.9075 16.8337 10.893 16.6803 10.8449 16.536L10.1579 14.474C10.1088 14.3269 10.0261 14.1932 9.91643 14.0835C9.80674 13.9738 9.67304 13.8911 9.5259 13.842Z"
        fill="#01ADFF"
      />
    </svg>
  );
}
